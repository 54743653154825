import React from "react"
import Register from '../components/RegisterHelper'
import Layout from "../components/Layout"
import Loginstyles from '../styles/LoginStyles';
import { graphql } from 'gatsby';

const RegisterPage = ({ location }) => {

  const { state: routeState } = location
  const redirect = (!routeState || routeState.redirect == null )
    ? '/'
    : routeState.redirect === 'app'
      ? '/app'
      : `/app/${routeState.redirect}`

  return (
    <Layout>
      <Loginstyles>   
        <Register redirect={redirect} coupon={0} />      
      </Loginstyles>
    </Layout>
  )
}

export default RegisterPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["logon", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;