import React, { useState } from "react"
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import useAuth from '../hooks/useAuth'
import { fetchUrl } from '../apiClient/baseApi';
import { Container, Row, Col } from "reactstrap";
import { StaticImage } from "gatsby-plugin-image";

const toastr = require('toastr')

const Register = ({ redirect, coupon }) => {
  const { navigate, t } = useI18next()
  const apiURL = process.env.GATSBY_API_URL
  const { isAuthenticated, login, social_login } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [couponCode, setCouponCode] = useState('')
  const [user, setUser] = React.useState({});

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (document.getElementById('password').value !== document.getElementById('confirm_password').value) {
      toastr.error('Passwords do not match')
      return false;
    }
    var data = coupon ? { "email": email, "password": password, "first_name": firstname, "last_name": lastname, "coupon_code": couponCode } :
      { "email": email, "password": password, "first_name": firstname, "last_name": lastname }
    try {
      await fetchUrl('post', '/users', data).then(async () => {
        await login({ email, password })
      })
      navigate(redirect)
    } catch (e) {
      console.log("Error occurred during authentication")
    }
  }

  const handleCouponSubmit = async (event) => {
    event.preventDefault()
    try {
      await fetchUrl('patch', '/users/' + user.id, { "coupon_code": couponCode }).then(async () => {
        await fetchUrl('get', '/users/me?fields=*.*').then((res) => {
          window.localStorage.setItem('user', JSON.stringify(res.data));
        })
        navigate(redirect)
      })
    } catch (e) {
      console.log("Error occurred while redeeming coupon code")
    }
  }

  const handleSocialSubmit = async (provider) => {
    try {
      await social_login(provider)
      await fetchUrl('get', '/users/me?fields=*.*').then((res) => {
        window.localStorage.setItem('user', JSON.stringify(res.data));
      })
      navigate(redirect)
    } catch (e) {
      console.log("Error occurred during authentication")
    }
  }

  const pwdCheck = () => {
    if (document.getElementById('password').value ===
      document.getElementById('confirm_password').value) {
      document.getElementById('message').style.color = 'green';
      document.getElementById('message').innerHTML = 'Password matching';
    } else {
      document.getElementById('message').style.color = 'red';
      document.getElementById('message').innerHTML = 'Password not matching';
    }
  }


  React.useEffect(() => {
    if (isAuthenticated) {
      setUser(JSON.parse(window.localStorage.getItem('user')));
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    if (isAuthenticated) {
      if (user && user.coupon_code) {
        setCouponCode(user.coupon_code);
      }
    }
  }, [isAuthenticated, user])

  const LinkStyle = {
    textDecoration: "none",
    color: "white",
  }

  const googleLink = apiURL + "/auth/login/google?redirect=" + apiURL + "/redirect-with-token"
  const fbLink = apiURL + "/auth/login/facebook?redirect=" + apiURL + "/redirect-with-token"

  return (
    <div className="login-screen siteStyl">
      <Container>
        <br />
        <Row className="display-flex">
          <Col lg="6" md="12" xl="6">
            <div style={{ color: "white" }}>
              <StaticImage src="../assets/images/Magic Author logo white.png" alt="Magic Author Logo" style={{ width: "95px", height: "150px" }} imgStyle={{ width: "95px", height: "150px" }} />
              <h1>{t('logon1')}</h1>
              <br />
              <p>{t('logon2')}</p>
            </div>
          </Col>

          <Col lg="6" md="12" xl="6">
            <div className="registration-login-form" data-mh="log-tab">
              {(isAuthenticated && coupon) ? <form className="registration-content" onSubmit={handleCouponSubmit}>
                <Row>
                  <div style={{ padding: "15px" }}>
                    <input placeholder={t('logon27')} type="text" id="coupon_code" required
                      onChange={e => {
                        setCouponCode(e.target.value)
                      }}
                      value={couponCode} />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <button type="submit" className="ma-btn">{t('logon23')}</button>
                  </div>
                </Row>
              </form> : <div>
                <form className="registration-content" onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="6" md="6">
                      <div style={{ padding: "15px" }}>
                        <input placeholder={t('logon9')} type="text" required
                          onChange={e => {
                            setFirstname(e.target.value)
                          }}
                          value={firstname} />
                      </div>
                    </Col>
                    <Col lg="6" md="6">
                      <div style={{ padding: "15px" }}>
                        <input placeholder={t('logon10')} type="text" required
                          onChange={e => {
                            setLastname(e.target.value)
                          }}
                          value={lastname} />
                      </div>
                    </Col>
                  </Row>

                  <div style={{ padding: "15px" }}>
                    <input placeholder={t('logon22')} type="email" required
                      onChange={e => {
                        setEmail(e.target.value)
                      }}
                      value={email} />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <input placeholder={t('logon11')} type="password" id="password" required
                      onChange={e => {
                        setPassword(e.target.value)
                      }}
                      value={password} />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <input placeholder={t('logon12')} type="password" id="confirm_password" required onKeyUp={() => pwdCheck()} />
                    <span id='message'></span>
                  </div>
                  {coupon ? <div style={{ padding: "15px" }}>
                    <input placeholder={t('logon27')} type="text" id="coupon_code" required
                      onChange={e => {
                        setCouponCode(e.target.value)
                      }}
                    />
                  </div> : ''}
                  <div style={{ padding: "5px" }}>
                    <Row>
                      <Col md="1">
                        <input name="optionsCheckboxes" type="checkbox" required />
                      </Col>
                      <Col md="11">
                        {t('logon3')} <Link to="/terms-and-conditions">{t('logon4')}</Link> {t('logon5')}
                      </Col>
                    </Row>
                  </div>
                  <p></p>
                  <div style={{ padding: "15px" }}>
                    <button type="submit" className="ma-btn">{t('logon6')}</button>
                  </div>
                </form>
                {coupon ? '' : <div>
                  <div className='or' />
                  <Container>
                    <a href={googleLink} rel="noopener noreferrer" style={LinkStyle}>
                      <div className="linkStyle1 google-btn">{t('logon18')}</div></a>
                    <p></p>
                    <a href={fbLink} rel="noopener noreferrer" style={LinkStyle}>
                      <div className="linkStyle1 fb-btn">{t('logon17')}</div></a>
                  </Container>
                </div>}
                <p></p>
                <p style={{ textAlign: "center" }}>{t('logon7')} <Link to="/login">{t('logon8')}</Link></p>
              </div>}
            </div>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  )
}

export default Register;
